import {
  AfterViewInit,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { filter, Subject, takeUntil } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { SignupDetailsComponent } from 'src/app/presentation/auth/signup/components/signup-details/signup-details.component';

@Component({
  selector: 'app-opt-in-details',
  templateUrl: './opt-in-details.component.html',
  styleUrls: ['./opt-in-details.component.scss'],
  standalone: true,
})
export class OptInDetailsComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('outlet', { read: ViewContainerRef })
  private _outlet: ViewContainerRef;

  onDetailsSuccess$: Subject<boolean> = new Subject<boolean>();

  onDestroy$: Subject<boolean> = new Subject<boolean>();

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  ngOnInit(): void {
    this._logMixpanelEventUseCase.execute({ eventName: 'opt_in_complete_profile_page_load' });
  }

  ngAfterViewInit(): void {
    this._injectDetailsComponentAndHandleEventsFromIt();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  private _injectDetailsComponentAndHandleEventsFromIt(): void {
    const componentRef = this._outlet.createComponent(SignupDetailsComponent);
    componentRef.instance.scope = 'opt-in';
    componentRef.instance.headerText = 'يرجى استكمال بيانات حسابك';
    componentRef.instance.subHeaderText = 'للتمكن من سحب ارباحك';
    componentRef.instance.nextStep = 'optInEnterOTP';
    componentRef.changeDetectorRef.detectChanges();
    componentRef.instance.goToNextStep$
      .pipe(
        filter((nextStep) => nextStep === 'optInEnterOTP'),
        takeUntil(this.onDestroy$),
      )
      .subscribe({ next: (_) => this.onDetailsSuccess$.next(true) });
  }
}
