import { AfterViewInit, Component, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { GetFeatureAttributeUsecase } from 'app/core/usecases/get-feature-attribute.usecase';
import { SignupOTPComponent } from 'app/presentation/auth/signup/components/signup-otp/signup-otp.component';
import { EMAIL_OTP_WITHDRAW } from 'app/presentation/shared/constants/feature-flags';
import { SharedOverlayService } from 'app/presentation/shared/services/shared-overlay.service';
import { featureAttributeAssign } from 'app/presentation/shared/utilities/feature-attribute-assign.utility';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUserStep } from 'src/app/presentation/shared/components/shared-stepper-indicator/interfaces';
import { UserResolutionService } from 'src/app/presentation/shared/services/user-resolution.service';
import { SharedStepperIndicatorComponent } from '../../../shared/components/shared-stepper-indicator/shared-stepper-indicator.component';
import { OptInDetailsComponent } from '../opt-in-details/opt-in-details.component';
import { OptInOtpComponent } from '../opt-in-otp/opt-in-otp.component';
// eslint-disable-next-line
import { user } from '@/v3/features/user/data';

@Component({
  selector: 'app-parent-opt-in',
  templateUrl: './parent-opt-in.component.html',
  styleUrls: ['./parent-opt-in.component.scss'],
  standalone: true,
  imports: [SharedStepperIndicatorComponent],
})
export class ParentOptInComponent implements AfterViewInit {
  @ViewChild('outlet', { read: ViewContainerRef, static: true })
  private _outlet: ViewContainerRef;

  currentUserStep$: Subject<CurrentUserStep> = new Subject<CurrentUserStep>();

  allowedUserSteps: Array<CurrentUserStep>;

  private _injectedComponentRef: ComponentRef<any>;

  onDestroy$: Subject<boolean> = new Subject<boolean>();

  public showEmailOTPonWithdrawal: boolean;

  constructor(
    private _userResolutionService: UserResolutionService,
    private _router: Router,
    private _sharedOverlayService: SharedOverlayService,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
  ) {
    this.allowedUserSteps = ['optInDetails', 'optInEnterPhoneNumber', 'optInEnterOTP'];
    this._shouldShowEmailOTPOnWithdrawal();
  }

  private _shouldShowEmailOTPOnWithdrawal(): void {
    this._getFeatureAttributeUseCase.execute(EMAIL_OTP_WITHDRAW).subscribe({
      next: (attribute) => {
        this.showEmailOTPonWithdrawal = featureAttributeAssign(attribute, user.id);
      },
    });
  }

  ngAfterViewInit(): void {
    const userAbilities = this._userResolutionService.analyzeUserToken();
    switch (userAbilities.nextStepForTheUser) {
      case 'details':
        this._injectOPTInDetailsComponent();
        break;
      case 'otp':
        // eslint-disable-next-line
        this.showEmailOTPonWithdrawal ? this._injectOTPComponentAndHandleEventsFromIt() : this._injectOPTInOTPComponent();
        break;
      case 'products':
        this._doNavigate('products');
        break;
    }
  }

  private _injectOPTInDetailsComponent(): void {
    this.currentUserStep$.next('optInDetails');
    this._doInjectComponent(OptInDetailsComponent);
    const OPTInDetailsComponent = this._injectedComponentRef as ComponentRef<OptInDetailsComponent>;
    OPTInDetailsComponent.instance.onDetailsSuccess$
      .pipe(takeUntil(OPTInDetailsComponent.instance.onDestroy$))
      .subscribe({ next: (_) => this._injectOPTInOTPComponent() });
  }

  private _injectOTPComponentAndHandleEventsFromIt(): void {
    const componentRef = this._outlet.createComponent(SignupOTPComponent);
    componentRef.instance.scope = 'opt-in';
    componentRef.changeDetectorRef.detectChanges();
    componentRef.instance.goToNextStep$.pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (status: Extract<CurrentUserStep, 'optInSuccessVerifyingOTP'>) => {
        switch (status) {
          case 'optInSuccessVerifyingOTP':
            this._sharedOverlayService.setOverlayType('success');
            break;
        }
      },
    });
  }

  private _injectOPTInOTPComponent(): void {
    this.currentUserStep$.next('optInEnterPhoneNumber');
    this._doInjectComponent(OptInOtpComponent);
    const OPTInOTPComponent = this._injectedComponentRef as ComponentRef<OptInOtpComponent>;
    OPTInOTPComponent.instance.currentOTPStep$
      .pipe(takeUntil(OPTInOTPComponent.instance.onDestroy$))
      .subscribe({ next: (nextUserStep) => this.currentUserStep$.next(nextUserStep) });
  }

  private _doNavigate(url: string): void {
    this._router.navigate([url]);
  }

  private _doInjectComponent(componentToInject: any): void {
    if (this._outlet) {
      this._outlet.clear();
    }
    if (this._injectedComponentRef) {
      this._injectedComponentRef.destroy();
    }
    this._injectedComponentRef = this._outlet.createComponent(componentToInject);
    this._injectedComponentRef.changeDetectorRef.detectChanges();
  }
}
