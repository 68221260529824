import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { CountryNotCoveredDialogComponent } from './components/country-not-covered-dialog/country-not-covered-dialog.component';
import { OptInDetailsComponent } from './components/opt-in-details/opt-in-details.component';
import { OptInOtpComponent } from './components/opt-in-otp/opt-in-otp.component';
import { ParentOptInComponent } from './components/parent-opt-in/parent-opt-in.component';
import { VerificationFailedDialogComponent } from './components/verification-failed-dialog/verification-failed-dialog.component';
import { VerificationSucceededDialogComponent } from './components/verification-succeeded-dialog/verification-succeeded-dialog.component';
import { UserVerificationComponent } from './user-verification.component';

const USER_VERIFICATION_COMPONENTS = [
  UserVerificationComponent,
  ParentOptInComponent,
  OptInOtpComponent,
  OptInDetailsComponent,
  VerificationFailedDialogComponent,
  VerificationSucceededDialogComponent,
  CountryNotCoveredDialogComponent,
];

@NgModule({
  imports: [CommonModule, ...USER_VERIFICATION_COMPONENTS],
  providers: [
    provideRouter([
      {
        path: '',
        pathMatch: 'full',
        component: UserVerificationComponent,
      },
    ]),
  ],
})
export class OptInModule {}
