<ng-container
  *ngTemplateOutlet="
    (currentOTPStep$ | async) === 'optInEnterPhoneNumber'
      ? enterPhoneNumberTemplate
      : enterOTPTemplate
  "
>
</ng-container>

<ng-template #enterPhoneNumberTemplate>
  <div class="opt-in-otp-container">
    <app-shared-notification-component
      *ngIf="responseMessage"
      [config]="responseMessage"
    ></app-shared-notification-component>
    <p class="header heading2--bold">يرجى تأكيد رقم الهاتف الخاص بك</p>
    <p class="sub-header body1--regular">حتي تتمكن من سحب ارباحك</p>
    <img
      loading="lazy"
      src="{{ authAssetsRelativePath + 'enter-opt-icon-1.svg' }}"
      alt="enter-opt-phone-number.svg"
    />
    <div class="user-hint">
      <img loading="lazy" src="{{ authAssetsRelativePath + 'info.svg' }}" />
      <p class="user-hint__statement body2--regular">
        يمكن استخدام رقم هاتفك كطريقة تسجيل دخول لحسابك.
      </p>
    </div>
    <form class="full-width" [formControl]="enterPhoneNumberForm" ngDefaultControl>
      <div class="phone-number-field">
        <app-meta-phone-number-field
          [fieldIsValid]="enterPhoneNumberForm.valid"
          [showValidationErrorIndicator]="true"
          [removeUncoveredCountries]="true"
          (result$)="phoneNumberChanges($event)"
        ></app-meta-phone-number-field>
      </div>
      <button
        class="get-otp-button body1--medium"
        type="submit"
        (click)="onSubmit()"
        [disabled]="enterPhoneNumberForm.invalid"
      >
        احصل على كود التأكيد
      </button>
    </form>
  </div>
</ng-template>

<ng-template #enterOTPTemplate>
  <div class="opt-in-otp-container">
    <ng-template #outlet></ng-template>
  </div>
</ng-template>
