import { Component } from '@angular/core';
import { PlainLayoutService } from '../shared/services/plain-layout.service';
import { ParentOptInComponent } from './components/parent-opt-in/parent-opt-in.component';

/**
 * This is the root optin component. It will be the one and only routing for the optin
 * since we will be injecting the rest of the contents onto it dynamically, within the
 * stepper component.
 */
@Component({
  templateUrl: 'user-verification.component.html',
  standalone: true,
  imports: [ParentOptInComponent],
})
export class UserVerificationComponent {
  constructor(private plainLayoutService: PlainLayoutService) {
    this.plainLayoutService.setCloseButton(true);
  }
}
